<div class="check-container">
    <input
        class="custom"
        [disabled]="disabled"
        [id]="id"
        type="checkbox"
        [(ngModel)]="value"
        (change)="setValue(value, $event)"
    >
    <label [for]="id" [ngClass]="{'after:!tw-bg-primary-base before:!tw-bg-primary-lighter': value, '!tw-bg-transparent': !value}">
        <span class="text">
            <ng-content></ng-content>
        </span>
    </label>
</div>
