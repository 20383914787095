import { INewUiState } from '@libs/store/new-ui/interfaces/new-ui-state.interface';

export const newUiInitialState: INewUiState = {
  searchLocationOption: 0,
  listFirstUpdateTimestamp: 0,
  viewStyle: 'list',
  isSavingNewPassword: false,
  isDownloadingLists: false,
  hasRedirectedOnSession: false,
  isRefreshButtonVisible: false,
  isUpsellVisible: false,
  webSocketStatus: {
    isReconnected: false,
    isOffline: false,
    isOnline: true,
  },
  resendEmailTimestamp: 0,
  emailConfirmation: '',
  hasLoadedPaymentProvider: false,
  isLoading: false,
  hasLoadedEmailSettings: false,
  hasLoadedPremiumSettings: false,
  hasLoadedPaymentHistory: false,
};
