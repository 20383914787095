import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { UserCommon } from '@libs/shared/user/user.common';
import { UserProfileSelectors } from '@libs/store/authentication';
import { NotificationsSelectors } from '@libs/store/notifications/selectors';
import { Config } from '@meupatrocinio/config';
import { NetcoreActions } from '@meupatrocinio/infra/netcore/actions/netcore.actions';
import { NetcoreScriptService } from '@meupatrocinio/infra/netcore/services/netcore-script.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class NetcoreEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private netcoreScriptService = inject(NetcoreScriptService);
  private authenticationService = inject(AuthenticationService);
  private rendererFactory = inject(RendererFactory2);

  private renderer2: Renderer2 = this.rendererFactory.createRenderer(null, null);

  initialize$ = createEffect(
    () =>
      this.authenticationService.onLogin$.pipe(
        withLatestFrom(this.store.select(UserProfileSelectors.selectUser)),
        filter(
          ([_, user]) =>
            Config.isNetCoreEnabled &&
            this.isUserEligibleForInjectNetcoreScript(user) &&
            !location.host.includes('localhost'),
        ),
        tap({
          next: () => {
            if (!this.netcoreScriptService.isNetCoreAlreadyInjected()) {
              this.netcoreScriptService.injectScript(this.renderer2);
            }

            this.netcoreScriptService.dispatchInitialEvents();
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  removeScript$ = createEffect(
    () =>
      this.authenticationService.onLogout$.pipe(
        tap({
          next: () => this.netcoreScriptService.removeCDNScript(this.renderer2),
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  dispatchCustomEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NetcoreActions.dispatchCustomEvent),
        withLatestFrom(
          this.store.select(UserProfileSelectors.selectUser),
          this.store.select(NotificationsSelectors.notificationsMarketing),
        ),
        filter(([_, { profile_id }]) => profile_id !== undefined),
        tap({
          next: ([{ eventName, params }, { profile_id, email_verified }, { email }]) => {
            this.netcoreScriptService.dispatchCustomEvent(
              params,
              {
                user_id: profile_id,
                email_verified,
                unsubscribed_email_marketing: email ? 'TRUE' : 'FALSE',
              },
              eventName,
            );
          },
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );

  private isUserEligibleForInjectNetcoreScript(user: UserCommon) {
    return user !== undefined && user.profile_id !== 0 && user?.status === UserCommon.STATUS_ACTIVE;
  }
}
