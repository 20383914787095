import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BoostProgressServiceCommon } from '@libs/modules/boost/services/boost-progress/boost-progress.service.common';
import { IBoostRunningInfo } from '@libs/store/boost/boost-running-info';
import { IBoostState } from '@libs/store/boost/interfaces/boost-state.interface';
import { boostAdapter } from '@libs/store/boost/state';

const selectFeature = createFeatureSelector<IBoostState>('boost');

export const selectIsActivatingBoost = createSelector(
  selectFeature,
  (state: IBoostState): boolean => state.isActivatingBoost,
);

export const selectIsActivationButtonDisabled = createSelector(
  selectFeature,
  (state: IBoostState): boolean => state.isActivationButtonDisabled,
);

export const selectAll = createSelector(selectFeature, boostAdapter.getSelectors().selectAll);

export const selectCanShowBoostList = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.canShowBoostList;
});

export const selectBoostRunningInfo = createSelector(selectFeature, (state: IBoostState): IBoostRunningInfo => {
  return state.boostRunningInfo;
});

export const selectHasBoostRunningOrBoostCredits = createSelector(selectFeature, (state: IBoostState): boolean => {
  const endTimestamp: number = state.boostRunningInfo.endTimestamp;
  const hasBoostRunning: boolean = endTimestamp !== 0 && endTimestamp > Date.now();
  const hasBoostCredits: boolean = state.balance > 0;

  return hasBoostRunning || hasBoostCredits;
});

export const selectBalance = createSelector(selectFeature, (state: IBoostState): number => {
  return state.balance;
});

export const selectHasBalance = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.balance > 0;
});

export const selectCanShowBoostModalResults = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.canShowBoostModalResults;
});

export const selectHasBoostEnded = createSelector(
  selectFeature,
  (state: IBoostState, props: { boostProgressService: BoostProgressServiceCommon }): boolean => {
    return props.boostProgressService.hasBoostEnded(state.boostRunningInfo.endTimestamp);
  },
);

export const selectMultiplier = createSelector(selectFeature, (state: IBoostState): number => {
  return state.multiplierValue;
});

export const selectIsProcessingBoostPrize = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.isProcessingBoostPrize;
});

export const selectHasResolvedBoostPackages = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.hasResolvedBoostPackages;
});

export const selectHasResolvedBalance = createSelector(selectFeature, (state: IBoostState): boolean => {
  return state.hasResolvedBalance;
});
