import { createAction, props } from '@ngrx/store';

import { ILatestBoost } from '@libs/modules/boost/interface/boost-bff-endpoints';
import { IBoostPackage } from '@libs/modules/boost/interface/boost-package';
import { IBoostRunningInfo } from '@libs/store/boost/boost-running-info';

enum Actions {
  SET_CAN_SHOW_BOOST_LIST = '[Boost] - Set can show boost list',
  SET_IS_ACTIVATING_BOOST = '[Boost] - Set Is Activating Boost Action',
  SET_BOOST_END_TIMESTAMP = '[Boost] - Set Boost Running Info',
  SET_CAN_SHOW_BOOST_MODAL_RESULT = '[Boost] - Set Can Show Boost Modal Result',
  INITIALIZE_BOOST_END_RESULTS_COUNTDOWN = '[Boost] - Initialize Boost End Results Countdown',
  STOP_BOOST_RESULTS_COUNTDOWN = '[Boost] - Stop Boost Results Countdown',
  SHOW_RESULTS_MODAL = '[Boost] - Show Results Modal',
  SHOW_PAYMENT_MODAL = '[Boost] - Show Payment Modal',
  SET_BALANCE = '[Boost] - Set Balance',
  PROVIDE_ALL_LAST_BOOST_VIEWS = '[Boost] - Provide All Last Boost Views',
  PROVIDE_MULTIPLIER_VALUE = '[Boost] - Provide Multiplier Value',
  SET_MULTIPLIER_VALUE = '[Boost] - Set Multiplier Value',
  UPDATE_BOOST_INFO = '[Boost] - Update Boost Info',
  HANDLE_UPDATE_BOOST_INFO = '[Boost] - Handle Update Boost Info',
  BOOST_INFO_UPDATED = '[Boost] - Boost Info Updated',
  CANCEL_BOOST_INFO_UPDATE = '[Boost] - Cancel Boost Info Update',
  GET_BALANCE = '[Boost] - Get Balance',
  GET_LATEST_BOOST_ACTIVATION = '[Boost] - Get Latest Boost Activation',
  ACTIVATE_BOOST = '[Boost] - Activate Boost',
  SET_IS_ACTIVATING_BOOST_WITH_DELAY = '[Boost] - Set Is Activating Boost With Delay',
  CHECK_IF_BOOST_CAN_BE_ACTIVATED = '[Boost] - Check If Boost Can Be Activated',
  CHECK_UPDATED_BALANCE_RESPONSE = '[Boost] - Check Updated Balance Response',
  SHOW_MODAL_BOOST = '[Boost] - Show Boost Modal',
  CHECK_BOOST_RUNNING = '[Boost] - Check Boost Running',
  ACTIVATED_BOOST = '[Boost] - Activated Boost',
  DECREMENT_CREDITS_FROM_USER = '[Boost] - Decrement credits from user',
  CAN_SHOW_BOOST_MODAL_RESULTS_ON_LOGIN = '[Boost] - Can Show Boost Modal Results On Login',
  CHECK_IF_MUST_LOAD_BOOSTED_PROFILES = '[Boost] - Check If Must Load Boosted Profiles',
  CHECK_VISIBILITY_BEFORE_BOOST = '[Boost] - Check Profile Visibility Before Boost',
  OPEN_VISIBILITY_MODAL_VIEW = '[Boost] - Open Visibility Modal View',
  SET_IS_ACTIVATION_BUTTON_DISABLED = '[Boost] - Set Activation Button Disabled State',
  SET_IS_PROCESSING_BOOST_PRIZE = '[Boost] - Set Is Processing Boost Prize',
  CHECK_PENDING_CREDITS = '[Boost] - Check Pending Credits',
  GET_BALANCE_AFTER_MEMBERSHIP_PAYMENT = '[Boost] - Get Balance After Membership Payment',
  START_PACKAGES_LOADED_CHECK_CYCLE = '[Boost] - Start Packages Loaded Check Cycle',
  HANDLE_PENDING_CREDITS_WARNING = '[Boost] - Handle Pending Credits Warning',
  SET_HAS_RESOLVED_BOOST_PACKAGES = '[Boost] - Set Has Resolved Boost Packages',
  SET_HAS_RESOLVED_BALANCE = '[Boost] - Set Has Resolved Balance',
}

export const setCanShowBoostList = createAction(
  Actions.SET_CAN_SHOW_BOOST_LIST,
  props<{ canShowBoostList: boolean }>(),
);

export const setIsActivatingBoost = createAction(
  Actions.SET_IS_ACTIVATING_BOOST,
  props<{ isActivatingBoost: boolean }>(),
);

export const setBoostRunningInfo = createAction(
  Actions.SET_BOOST_END_TIMESTAMP,
  props<{ boostRunning: IBoostRunningInfo }>(),
);

export const setCanShowBoostModalResults = createAction(
  Actions.SET_CAN_SHOW_BOOST_MODAL_RESULT,
  props<{ canShowBoostModalResults: boolean }>(),
);

export const initializeBoostEndResultsCountdown = createAction(Actions.INITIALIZE_BOOST_END_RESULTS_COUNTDOWN);

export const stopBoostResultsCountdown = createAction(Actions.STOP_BOOST_RESULTS_COUNTDOWN);

export const showResultsModal = createAction(Actions.SHOW_RESULTS_MODAL);

export const handleUpdateBoostInfo = createAction(Actions.HANDLE_UPDATE_BOOST_INFO);

export const updateBoostInfo = createAction(Actions.UPDATE_BOOST_INFO, props<{ millisecondsToUpdate: number }>());

export const boostInfoUpdated = createAction(Actions.BOOST_INFO_UPDATED);

export const cancelBoostInfoUpdate = createAction(Actions.CANCEL_BOOST_INFO_UPDATE);

export const showPaymentModal = createAction(
  Actions.SHOW_PAYMENT_MODAL,
  props<{ selectedBoostPackage: IBoostPackage }>(),
);

export const setBalance = createAction(Actions.SET_BALANCE, props<{ balance: number }>());

export const provideAllLastBoostViewers = createAction(Actions.PROVIDE_ALL_LAST_BOOST_VIEWS);

export const provideMultiplierValue = createAction(
  Actions.PROVIDE_MULTIPLIER_VALUE,
  props<{ profilesLength: number }>(),
);

export const setMultiplierValue = createAction(Actions.SET_MULTIPLIER_VALUE, props<{ multiplierValue: number }>());

export const getBalance = createAction(Actions.GET_BALANCE);

export const getLatestBoostActivation = createAction(Actions.GET_LATEST_BOOST_ACTIVATION);

export const activateBoost = createAction(Actions.ACTIVATE_BOOST);

export const checkIfBoostCanBeActivated = createAction(Actions.CHECK_IF_BOOST_CAN_BE_ACTIVATED);

export const checkUpdatedBalanceResponse = createAction(
  Actions.CHECK_UPDATED_BALANCE_RESPONSE,
  props<{ currentBalance: number; updatedBalance: number }>(),
);

export const checkBoostRunning = createAction(Actions.CHECK_BOOST_RUNNING);

export const showModalBoost = createAction(Actions.SHOW_MODAL_BOOST, props<{ message: string }>());

export const activatedBoost = createAction(Actions.ACTIVATED_BOOST, props<{ latestBoost: ILatestBoost }>());

export const setIsActivatingBoostWithDelay = createAction(
  Actions.SET_IS_ACTIVATING_BOOST_WITH_DELAY,
  props<{ isActivatingBoost: boolean }>(),
);

export const decrementCreditsFromUser = createAction(Actions.DECREMENT_CREDITS_FROM_USER);

export const canShowBoostModalResultsOnLogin = createAction(Actions.CAN_SHOW_BOOST_MODAL_RESULTS_ON_LOGIN);

export const checkVisibilityBeforeBoost = createAction(Actions.CHECK_VISIBILITY_BEFORE_BOOST);

export const openVisibilityModal = createAction(Actions.OPEN_VISIBILITY_MODAL_VIEW);

export const setIsActivationButtonDisabled = createAction(
  Actions.SET_IS_ACTIVATION_BUTTON_DISABLED,
  props<{ isDisabled: boolean }>(),
);

export const setIsProcessingBoostPrize = createAction(
  Actions.SET_IS_PROCESSING_BOOST_PRIZE,
  props<{ isProcessing: boolean }>(),
);

export const checkPendingCredits = createAction(Actions.CHECK_PENDING_CREDITS);

export const getBalanceAfterMembershipPayment = createAction(Actions.GET_BALANCE_AFTER_MEMBERSHIP_PAYMENT);

export const startPackagesLoadedCheckCycle = createAction(Actions.START_PACKAGES_LOADED_CHECK_CYCLE);

export const handlePendingCreditsWarning = createAction(Actions.HANDLE_PENDING_CREDITS_WARNING);

export const setHasResolvedBoostPackages = createAction(
  Actions.SET_HAS_RESOLVED_BOOST_PACKAGES,
  props<{ hasResolvedBoostPackages: boolean }>(),
);

export const setHasResolvedBalance = createAction(
  Actions.SET_HAS_RESOLVED_BALANCE,
  props<{ hasResolvedBalance: boolean }>(),
);
