import { createAction } from '@ngrx/store';

enum Actions {
  MAIN_MODULE_LOADED = '[Main] - Module Loaded',
  USER_INITIALIZED = '[Main / User] - Initialized',
}

export const moduleLoaded = createAction(Actions.MAIN_MODULE_LOADED);

export const userInitialized = createAction(Actions.USER_INITIALIZED);
