import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PaymentCommon } from '@libs/modules/main/services/payment/payment.common';
import { IPaymentOptionState } from '@libs/store/payment/reducers';

export const selectPaymentOptionState = createFeatureSelector<IPaymentOptionState>('paymentState');

export const selectSelectedProvider = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): string => state.selectedProvider,
);

export const selectHasCreditCardPaymentType = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => PaymentCommon.hasCreditCardPaymentType(state.payment_types),
);

export const selectHasBoletoPaymentType = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => PaymentCommon.hasBoletoPaymentType(state.payment_types),
);

export const selectCurrentPayment = createSelector(selectPaymentOptionState, (state) => {
  return state.currentPayment;
});

export const selectHasResolvedProvider = createSelector(
  selectPaymentOptionState,
  (state: IPaymentOptionState): boolean => state.hasResolvedProvider,
);
