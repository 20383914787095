import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { notFoundError } from '@libs/modules/boost/helpers/boost-bff-404-error';
import { IDecrementResponse, ILatestBoost } from '@libs/modules/boost/interface/boost-bff-endpoints';
import { BoostServiceCommon } from '@libs/modules/boost/services/boost/boost.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { IApplicationState } from '@libs/store/application-state';
import { BoostActions } from '@libs/store/boost';
import { BoostLastViewersActions } from '@libs/store/boost-last-viewers';
import { BoostedProfilesActions } from '@libs/store/boosted-profiles';

import { BoostEligibilityHelpers } from '@meupatrocinio/modules/boost/helpers/boost-eligibility-helpers';
import { boostBFF } from '@meupatrocinio/modules/boost/helpers/boost-endpoints';
import { BoostProgressService } from '@meupatrocinio/modules/shared/boost-shared/services/boost-progress/boost-progress.service';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';
import { AuthenticationService } from '@meupatrocinio/services/authentication.service';
import { DownloadManagerService } from '@meupatrocinio/services/download-manager.service';
import { ProfileService } from '@meupatrocinio/services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class BoostService extends BoostServiceCommon {
  constructor(
    protected store: Store<IApplicationState>,
    protected downloadManagerService: DownloadManagerService,
    protected profileService: ProfileService,
    protected boostProgressService: BoostProgressService,
    protected authHttp: AuthHttpService,
    protected auth: AuthenticationService,
  ) {
    super(store, downloadManagerService, profileService, boostProgressService, authHttp, auth);
  }

  public getLatestBoostActivation$(): Observable<ILatestBoost> {
    return this.authHttp.get(boostBFF.latestBoost).pipe(
      map((authResponse: IAuthResponse<ILatestBoost>): ILatestBoost => {
        if (authResponse.data.profileUuid === undefined) {
          throw notFoundError;
        }

        return authResponse.data;
      }),
    );
  }

  public decrementBoostCredit(): Observable<IDecrementResponse> {
    return this.authHttp
      .post(boostBFF.boostUser, {})
      .pipe(map((authResponse: IAuthResponse<IDecrementResponse>): IDecrementResponse => authResponse.data));
  }

  public loadBoostFeature() {
    if (!BoostEligibilityHelpers.isBoostEnabled()) {
      return;
    }

    this.loadBoostedProfiles();
    this.getLatestBoostActivation();
    this.triggerUpdateBoostInfo();
    this.initializeBoostEndResultsCountdown();
    this.loadLastViewers();
    this.initializeBoostResultsModal();
  }

  private loadBoostedProfiles(): void {
    this.store.dispatch(BoostedProfilesActions.loadBoostedProfiles());
  }

  private getLatestBoostActivation(): void {
    this.store.dispatch(BoostActions.getLatestBoostActivation());
  }

  private triggerUpdateBoostInfo(): void {
    this.store.dispatch(BoostActions.handleUpdateBoostInfo());
  }

  private initializeBoostEndResultsCountdown(): void {
    this.startBoostEndResultsCountdown();
  }

  private loadLastViewers(): void {
    this.store.dispatch(BoostLastViewersActions.loadLastViewers());
  }

  private initializeBoostResultsModal(): void {
    this.store.dispatch(BoostActions.canShowBoostModalResultsOnLogin());
  }
}
