import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { PaymentStatus } from '@libs/modules/main/services/payment/payment.common';
import { ProviderV2Actions } from '@libs/modules/payment-v2/actions';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';
import { PaymentInfoActions, PaymentSelectors } from '@libs/store/payment-info';
import { exponentialBackoff } from '@libs/utils/observable-helpers/observable-helpers';

import { ITokenizeCreditCardResponse } from '@libs/modules/payment-v2/interfaces/tokenize-credit-card-response.interface';
import { PaymentFlowHandlersService } from '@meupatrocinio/modules/payment-v2/payment-flow-handlers/payment-flow-handlers.service';
import { AllCashV2Actions } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/actions';
import { AllCashV2Service } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/services/allcash-v2.service';
import { PagSeguroV2Actions } from '@meupatrocinio/modules/payment-v2/providers/pagseguro-v2/actions';

@Injectable()
export class AllCashV2Effects {
  private actions$ = inject(Actions);
  private allCashService = inject(AllCashV2Service);
  private store = inject(Store);
  private paymentFlowHandlersService = inject(PaymentFlowHandlersService);

  handleCreditCardPayment$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AllCashV2Actions.handleCreditCardPayment),
        switchMap(({ paymentData, productDescription }): Observable<Action> => {
          const { cardInfo, holderInfo, purchaseInfo } = paymentData;

          this.paymentFlowHandlersService.handlePaymentAttemptInitialActions({
            paymentProvider: 'allcash',
            value: purchaseInfo.subtotalAmount,
            product: purchaseInfo.product.product_uuid,
            productDescription,
          });

          return this.allCashService.encryptCard(paymentData).pipe(
            concatMap((encryptedCard: string) => {
              if (!encryptedCard || !encryptedCard.length) {
                throw new Error('Failed to encrypt card.');
              }

              return this.allCashService.tokenizeCreditCard(encryptedCard).pipe(
                exponentialBackoff(),
                map((response: IAuthResponse<ITokenizeCreditCardResponse>) => {
                  return AllCashV2Actions.createCreditCardPayment({
                    externalIdentifier: response.data.externalIdentifier,
                    purchaseInfo,
                    holderInfo,
                    cardInfo,
                  });
                }),
                catchError(() => {
                  return this.paymentFlowHandlersService.handleTokenizationError();
                }),
              );
            }),
            catchError(() => {
              return this.paymentFlowHandlersService.handleEncryptionError();
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  createCreditCardPayment$: Observable<Action> = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(AllCashV2Actions.createCreditCardPayment),
        withLatestFrom(this.store.select(PaymentSelectors.selectCurrentPayment)),
        switchMap(([paymentData, currentPayment]): Observable<Action> => {
          const DELAY_TO_CHECK_PAYMENT_STATUS = 4000 as const;
          const { externalIdentifier, purchaseInfo, holderInfo, cardInfo } = paymentData;

          return this.allCashService
            .createCreditCardPayment({
              encryptedCard: externalIdentifier,
              purchaseInfo,
              holderInfo,
              cardInfo,
              idempotencyKey: currentPayment.idempotencyKey,
            })
            .pipe(
              exponentialBackoff(),
              concatMap((): Observable<Action> => {
                return of(ProviderV2Actions.checkPaymentStatus()).pipe(delay(DELAY_TO_CHECK_PAYMENT_STATUS));
              }),
              catchError((): Observable<Action> => {
                return this.paymentFlowHandlersService.handleCreatePaymentError();
              }),
            );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  createBoletoPayment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AllCashV2Actions.createBoletoPayment),
        tap({
          next: () => {
            this.paymentFlowHandlersService.setIsPaying(true);
          },
        }),
        switchMap(({ paymentData }) => {
          return this.allCashService.createBoletoPayment({ paymentData }).pipe(
            exponentialBackoff(),
            map((response: IAuthResponse<{ url: string }>) => {
              this.paymentFlowHandlersService.handleBoletoGenerationSuccess(response);

              return PaymentInfoActions.setPaymentStatus({
                paymentStatus: PaymentStatus.PAYMENT_NONE,
              });
            }),
            catchError((error) => {
              this.paymentFlowHandlersService.handleBoletoGenerationError(error);

              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: true, useEffectsErrorHandler: false },
  );

  tokenizeOnPagseguroPayments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PagSeguroV2Actions.tokenizeForAllCash),
        concatMap(({ paymentData }) => {
          return this.allCashService.encryptCard(paymentData).pipe(
            concatMap((encryptedCard) => {
              if (!encryptedCard || !encryptedCard.length) {
                throw new Error('Failed to encrypt card.');
              }

              return this.allCashService.tokenizeCreditCard(encryptedCard).pipe(
                exponentialBackoff(),
                concatMap(() => {
                  return EMPTY;
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: true },
  );
}
