import { Observable } from 'rxjs';

import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';
import { ICreatePaymentResponse } from '@libs/modules/payment-v2/interfaces/create-payment-response.interface';
import { ICreatePixPayment } from '@libs/modules/payment-v2/interfaces/create-pix-payment.interface';
import { ICreditCardFormInfo } from '@libs/modules/payment-v2/interfaces/credit-card-form-info.interface';
import { ICreditCardHolderInfo } from '@libs/modules/payment-v2/interfaces/credit-card-holder-info.interface';
import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';
import { IPurchaseInfo } from '@libs/modules/payment-v2/interfaces/purchase-info.interface';
import { ITokenizeCreditCardResponse } from '@libs/modules/payment-v2/interfaces/tokenize-credit-card-response.interface';
import { AuthHttpServiceCommon } from '@libs/services/auth-http/auth-http.service.common';
import { IAuthResponse } from '@libs/services/auth-http/auth-response.interface';

export abstract class PaymentHttpService {
  constructor(protected authHttpService: AuthHttpServiceCommon) {
    //
  }

  protected abstract getProviderIdentifier(): string;

  protected abstract getServerIp(): string;

  public abstract encryptCard(preparedCreditCardInfo: IPaymentAttemptData): Observable<string>;

  public tokenizeCreditCard(encryptedCard: string): Observable<IAuthResponse<ITokenizeCreditCardResponse>> {
    const endpoint: string = `${this.getServerIp()}v2/payment/tokenization-credit-card`;
    const data = {
      cardToken: encryptedCard,
      providerExternalIdentifier: this.getProviderIdentifier(),
    };

    return this.authHttpService.post(endpoint, data);
  }

  public createCreditCardPayment(paymentData: {
    encryptedCard: string;
    purchaseInfo: IPurchaseInfo;
    holderInfo: ICreditCardHolderInfo;
    cardInfo: ICreditCardFormInfo;
    idempotencyKey: string;
  }): Observable<IAuthResponse<ICreatePaymentResponse>> {
    const endpoint: string = `${this.getServerIp()}v2/payment/create-payment`;
    const { encryptedCard, purchaseInfo, holderInfo, cardInfo, idempotencyKey } = paymentData;
    const data = {
      providerExternalIdentifier: this.getProviderIdentifier(),
      creditCardData: encryptedCard,
      productUuid: purchaseInfo.product?.product_uuid,
      variantUuid: purchaseInfo.product?.variant_uuid,
      numberOfInstallments: cardInfo.installments,
      firstDigits: this.getFirstSixDigits(cardInfo.cardNumber),
      lastDigits: this.getFourLastDigits(cardInfo.cardNumber),
      taxId: holderInfo.cpf,
    };
    const customHeaders = {
      'Idempotency-Key': idempotencyKey,
    };

    return this.authHttpService.post(endpoint, data, null, customHeaders);
  }

  private getFirstSixDigits(cardNumber: string) {
    return cardNumber.substring(0, 6);
  }

  private getFourLastDigits(cardNumber: string) {
    return cardNumber.substring(cardNumber.length - 4, cardNumber.length);
  }

  public createBoletoPayment({ paymentData }: ICreateBoletoPayment) {
    const { taxId, productUuid, variantUuid, idempotencyKey } = paymentData;

    if (!productUuid || !variantUuid) {
      throw new Error('Product data is missing');
    }

    const endpoint: string = `${this.getServerIp()}v2/payment/generate-boleto`;
    const data = {
      providerExternalIdentifier: this.getProviderIdentifier(),
      productUuid,
      variantUuid,
      taxId,
    };
    const customHeaders = {
      'Idempotency-Key': idempotencyKey,
    };

    return this.authHttpService.post(endpoint, data, null, customHeaders);
  }

  public createPixPayment({ paymentData }: ICreatePixPayment) {
    const { product, taxId: cpf, idempotencyKey } = paymentData;
    const { product_uuid: productUuid, variant_uuid: variantUuid } = product;

    if (!productUuid || !variantUuid) {
      throw new Error('Product data is missing');
    }

    const endpoint = `${this.getServerIp()}v2/payment/create-pix-payment`;
    const payloadData = {
      productUuid,
      variantUuid,
      taxId: cpf,
      providerExternalIdentifier: this.getProviderIdentifier(),
    };
    const customHeaders = {
      'Idempotency-Key': idempotencyKey,
    };

    return this.authHttpService.post(endpoint, payloadData, null, customHeaders);
  }
}
