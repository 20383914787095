import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';
import { ICreateCreditCardPayment } from '@libs/modules/payment-v2/interfaces/create-credit-card-payment.interface';
import { ICreatePixPayment } from '@libs/modules/payment-v2/interfaces/create-pix-payment.interface';
import { IHandleCreditCardPayment } from '@libs/modules/payment-v2/interfaces/handle-credit-card-payment.interface';
import { createActionGroup, props } from '@ngrx/store';

export const Safe2PayActions = createActionGroup({
  source: 'Safe2Pay',
  events: {
    'Handle Credit Card Payment': props<IHandleCreditCardPayment>(),
    'Create Credit Card Payment': props<ICreateCreditCardPayment>(),
    'Create Boleto Payment': props<ICreateBoletoPayment>(),
    'Create Pix Payment': props<ICreatePixPayment>(),
  },
});
