import { createAction, props } from '@ngrx/store';

import { ViewStyle } from '@libs/interfaces/list-view-style/list-view-style.type';
import { IWebSocketStatus } from '@libs/interfaces/socket/socket.service.interface';

enum Actions {
  SET_PREFERRED_VIEW_STYLE = '[UI] - Set Preferred View Style',
  SET_SEARCH_LOCATION_OPTION = '[Search] - Set Search Location Option',
  SET_HAS_REDIRECTED_ON_SESSION = '[ABTests] - Set Has Redirected on Session',
  UPDATE_LIST_FIRST_UPDATE_TIMESTAMP = '[Lists] - Update List First Update Timestamp',
  SET_LIST_FIRST_UPDATE_TIMESTAMP = '[Lists] - Set List First Update Timestamp',
  REFRESH_PRIMARY_LISTS = '[Lists] - Refresh Primary Lists',
  SET_IS_REFRESH_BUTTON_VISIBLE = '[UI] - Set Is Refresh Button Visible',
  SET_IS_UPSELL_VISIBLE = '[UI] - Set Is Upsell Visible',
  SET_WEBSOCKET_STATUS = '[UI] - Set Websocket Status',
  SET_RESEND_EMAIL_TIMESTAMP = '[UI] - Set Resend Email Timestamp',
  SET_EMAIL_CONFIRMATION = '[UI] - Set Email Confirmation',
  REFRESH_ACTIVE_LIST = '[Lists] - Refresh Active List',
  SET_HAS_LOADED_PAYMENT_PROVIDER = '[Payment] - Set Has Loaded Payment Provider',
  SET_IS_LOADING = '[UI] -  Set Is Loading',
  SET_HAS_LOADED_EMAIL_SETTINGS = '[UI] - Set Has Loaded Email Settings',
  SET_HAS_LOADED_PREMIUM_SETTINGS = '[UI] - Set Has Loaded Premium Settings',
  SET_HAS_LOADED_PAYMENT_HISTORY = '[Payment] - Set Has Loaded Payment History',
}

export const setPreferredViewStyle = createAction(Actions.SET_PREFERRED_VIEW_STYLE, props<{ viewStyle: ViewStyle }>());

export const setSearchLocationOption = createAction(
  Actions.SET_SEARCH_LOCATION_OPTION,
  props<{ searchLocationOption: number }>(),
);

export const setHasRedirectedOnSession = createAction(Actions.SET_HAS_REDIRECTED_ON_SESSION);

export const updateListFirstUpdateTimestamp = createAction(Actions.UPDATE_LIST_FIRST_UPDATE_TIMESTAMP);

export const setListFirstUpdateTimestamp = createAction(
  Actions.SET_LIST_FIRST_UPDATE_TIMESTAMP,
  props<{ listFirstUpdateTimestamp: number; isPageRefresh?: boolean }>(),
);

export const setIsRefreshButtonVisible = createAction(
  Actions.SET_IS_REFRESH_BUTTON_VISIBLE,
  props<{ isRefreshButtonVisible: boolean }>(),
);

export const refreshPrimaryLists = createAction(Actions.REFRESH_PRIMARY_LISTS);

export const setIsUpsellVisible = createAction(Actions.SET_IS_UPSELL_VISIBLE, props<{ isOpen: boolean }>());

export const setWebSocketStatus = createAction(
  Actions.SET_WEBSOCKET_STATUS,
  props<{ webSocketStatus: IWebSocketStatus }>(),
);

export const setResendEmailTimestamp = createAction(
  Actions.SET_RESEND_EMAIL_TIMESTAMP,
  props<{ resendEmailTimestamp: number }>(),
);

export const setEmailConfirmation = createAction(Actions.SET_EMAIL_CONFIRMATION, props<{ email: string }>());

export const refreshActiveList = createAction(Actions.REFRESH_ACTIVE_LIST);

export const setHasLoadedPaymentProvider = createAction(
  Actions.SET_HAS_LOADED_PAYMENT_PROVIDER,
  props<{ hasLoadedPaymentProvider: boolean }>(),
);

export const setIsLoading = createAction(Actions.SET_IS_LOADING, props<{ isLoading: boolean }>());

export const setHasLoadedEmailSettings = createAction(Actions.SET_HAS_LOADED_EMAIL_SETTINGS);

export const setHasLoadedPremiumSettings = createAction(Actions.SET_HAS_LOADED_PREMIUM_SETTINGS);

export const setHasLoadedPaymentHistory = createAction(
  Actions.SET_HAS_LOADED_PAYMENT_HISTORY,
  props<{ hasLoadedPaymentHistory: boolean }>(),
);
