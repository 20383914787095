import { createActionGroup, props } from '@ngrx/store';

import { ICreateBoletoPayment } from '@libs/modules/payment-v2/interfaces/create-boleto-payment.interface';

import { ICreateCreditCardPayment } from '@libs/modules/payment-v2/interfaces/create-credit-card-payment.interface';
import { IHandleCreditCardPayment } from '@libs/modules/payment-v2/interfaces/handle-credit-card-payment.interface';

export const AllCashV2Actions = createActionGroup({
  source: 'AllCashV2',
  events: {
    'Handle Credit Card Payment': props<IHandleCreditCardPayment>(),
    'Create Credit Card Payment': props<ICreateCreditCardPayment>(),
    'Create Boleto Payment': props<ICreateBoletoPayment>(),
  },
});
