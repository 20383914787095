import { Injectable } from '@angular/core';
import { IPaymentAttemptData } from '@libs/modules/payment-v2/interfaces/payment-data-attempt.interface';
import { PaymentHttpService } from '@libs/modules/payment-v2/services/payment-http.service';
import { Config } from '@meupatrocinio/config';
import { AllCashEncryptionService } from '@meupatrocinio/modules/payment-v2/providers/allcash-v2/services/allcash-encryption.service';
import { AuthHttpService } from '@meupatrocinio/services/auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class Safe2PayService extends PaymentHttpService {
  private readonly PROVIDER_IDENTIFIER = '2dfaf305-7ea7-4730-ae41-6138926ac8be';

  constructor(
    protected readonly authHttpService: AuthHttpService,
    protected readonly allcashEncryptionService: AllCashEncryptionService,
  ) {
    super(authHttpService);
  }

  protected getProviderIdentifier(): string {
    return this.PROVIDER_IDENTIFIER;
  }

  protected getServerIp(): string {
    return Config.serverIp;
  }

  public encryptCard(paymentData: IPaymentAttemptData) {
    return this.allcashEncryptionService.encrypt(paymentData);
  }
}
