import { createAction, props } from '@ngrx/store';

import { IBoostPackageProduct } from '@libs/modules/boost/interface/boost-package-product';
import { MembershipType } from '@libs/shared/membership/membership.common';
import { ICurrentPayment } from '@libs/store/payment/interfaces/currrent-payment.interface';

enum Actions {
  SET_PROVIDER_PAYMENT_TYPES = '[Payment] - Set Provider Payment Types',
  PAYMENT_ERROR = '[Payment Effect] - Payment Error',
  SET_SELECTED_PROVIDER = '[Component] - Set selected provider',
  STORE_CURRENT_PAYMENT = '[Payment] - Store Current Payment',
  SET_HAS_RESOLVED_PROVIDER = '[Payment] - Set Has Resolved Provider',
}

export const setProviderPaymentTypes = createAction(
  Actions.SET_PROVIDER_PAYMENT_TYPES,
  props<{ payment_types: string[] }>(),
);

export const paymentError = createAction(
  Actions.PAYMENT_ERROR,
  props<{ product: MembershipType | IBoostPackageProduct }>(),
);

export const setSelectedProvider = createAction(
  Actions.SET_SELECTED_PROVIDER,
  (provider: string): { provider: string } => ({ provider }),
);

export const storeCurrentPayment = createAction(
  Actions.STORE_CURRENT_PAYMENT,
  props<{ currentPayment: ICurrentPayment }>(),
);

export const setHasResolvedProvider = createAction(
  Actions.SET_HAS_RESOLVED_PROVIDER,
  props<{ hasResolvedProvider: boolean }>(),
);
